.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
  width: 100svw;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form__control {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.form__submit {
  display: flex;
  justify-content: center;
}

h2 {
  text-align: center;
}

.reaction-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 8px;
}

.reaction-list__item {
  display: flex;
  gap: 4px;
  align-items: center;
}

.increment, .decrement {
  border: none;
  background-color: transparent;
  padding: 4px 8px;
  cursor: pointer;
}